import './navbar.scss';
import '../../../pages/html/navbar/menu/menu.js'
import '../../../pages/html/navbar/mobile_menu/mobile_menu.js'
import '../../../pages/html/navbar/banner/banner.js'

document.addEventListener('DOMContentLoaded', function() {
  const header = document.querySelector('.navbar.design_v2');
  if (header) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    });
  }
});
