import './mobile_menu.scss';

document.addEventListener('DOMContentLoaded', () => {
  const wrapper = document.querySelector('.navbar-mobile-menu');
  const mobileButton = wrapper.querySelector('.navbar-mobile-menu--button');
  const content = wrapper.querySelector('.navbar-mobile-menu--wrapper');
  //const tabs = wrapper.querySelectorAll('.navbar-mobile-menu--wrapper--content--tabs--item');
  const tabsContent = wrapper.querySelectorAll('.navbar-mobile-menu--wrapper--content--body');
  const tabsMenus = wrapper.querySelectorAll('.navbar-mobile-menu--wrapper--content--body--menu--name');
  const subMenus = wrapper.querySelectorAll('.navbar-mobile-menu--wrapper--content--body--menu--list--item--text');

  mobileButton.addEventListener('click', () => {
    content.classList.add('show');
  })

  content.addEventListener('click', (e) => {
    if (e.target === content) {
      content.classList.remove('show');
    }
  })

  // tabs.forEach(tab => {
  //   tab.addEventListener('click', () => {
  //     switchTab(tab);
  //   })
  // })

  tabsMenus.forEach(menu => {
    menu.addEventListener('click', () => {
      toggleMenu(menu);
    })
  })

  subMenus.forEach(subMenu => {
    subMenu.addEventListener('click', () => {
      toggleSubMenu(subMenu);
    })
  })

  function switchTab(tab) {
    const tabName = tab.dataset.tabName;
    tabs.forEach(tab => {
      tab.classList.remove('active')
    })
    tab.classList.add('active');

    tabsContent.forEach(content => {
      if (content.dataset.bodyName === tabName) {
        content.classList.add('show');
      } else {
        content.classList.remove('show');
      }
    })
  }

  function toggleMenu(menu) {
    const menuWrapper = menu.closest('.navbar-mobile-menu--wrapper--content--body--menu');
    if (menuWrapper.classList.contains('active')) {
      menuWrapper.classList.remove('active');
    } else {
      menuWrapper.classList.add('active');
    }
  }

  function toggleSubMenu(subMenu) {
    const subMenuWrapper = subMenu.closest('.submenu');
    if (subMenuWrapper.classList.contains('sub-active')) {
      subMenuWrapper.classList.remove('sub-active');
    } else {
      subMenuWrapper.classList.add('sub-active');
    }
  }
})
